input, textarea, select {
  background: transparent;
  color: var(--accent);
  border: 1px solid var(--accent);
  border-radius: 0;
  padding: 10px;
  margin: 5px 0;
  font: inherit;
  appearance: none;

  &:focus, :active {
    border-color: var(--color);
    outline: 1px solid var(--color);
  }

  &:active {
    box-shadow: none;
  }
}

select {
  background: var(--background);

  option {
    background: var(--background);
  }
}

::placeholder {
  color: color-mix(in srgb, var(--accent), transparent 50%);
}

input {
  &[type="checkbox"] {
    vertical-align: middle;
    padding: 10px;
    box-shadow: inset 0 0 0 3px var(--background);

    &:checked {
      background: var(--accent);
    }
  }
}
