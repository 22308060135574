code.language-css,
code.language-scss,
.token.boolean,
.token.string,
.token.entity,
.token.url,
.language-css .token.string,
.language-scss .token.string,
.style .token.string,
.token.attr-value,
.token.keyword,
.token.control,
.token.directive,
.token.statement,
.token.regex,
.token.atrule,
.token.number,
.token.inserted,
.token.important {
  color: var(--syntax-value-color, color-mix(in srgb, var(--accent), white)) !important;
}

.token.tag-id,
.token.atrule-id,
.token.operator,
.token.unit,
.token.placeholder,
.token.variable,
.token.tag,
.token.attr-name,
.token.namespace,
.token.deleted,
.token.property,
.token.class-name,
.token.constant,
.token.symbol {
  color: var(--syntax-var-color, color-mix(in srgb, var(--accent) 90%, transparent)) !important;
}

.token.property,
.token.function,
.token.function-name,
.token.deleted,
code.language-javascript,
code.language-html,
.command-line-prompt > span:before {
  color: var(--syntax-func-color, color-mix(in srgb, var(--accent) 70%, #999 30%)) !important;
}

.token.selector,
.token.tag,
.token.punctuation {
  color: white;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: rgba(255, 255, 255, .3) !important;
}

.token.namespace {
  opacity: .7 !important;
}

pre[data-line] {
  position: relative;
}

pre[class*="language-"] {
  margin: 0;
  padding: 0;
  overflow: auto;
}

.line-highlight {
  --line-highlight-mix: color-mix(in srgb, var(--accent) 90%, #999 10%);
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  background: color-mix(in srgb, var(--line-highlight-mix), transparent 92%);
  pointer-events: none;
  line-height: inherit;
  white-space: pre;
}

.line-highlight:before,
.line-highlight[data-end]:after {
  content: attr(data-start);
  position: absolute;
  /* top: .4em; */
  left: .6em;
  min-width: 1em;
  padding: 0 .5em;
  background-color: hsla(24, 20%, 50%, .4);
  color: hsl(24, 20%, 95%);
  font: bold 65%/1.5 sans-serif;
  text-align: center;
  vertical-align: .3em;
  border-radius: 999px;
  text-shadow: none;
  box-shadow: 0 1px white;
}

.line-highlight[data-end]:after {
  content: attr(data-end);
  top: auto;
  bottom: .4em;
}

.line-numbers .line-highlight:before,
.line-numbers .line-highlight:after {
  content: none;
}

.code-toolbar {
  --code-margin: 40px;
	position: relative;
	margin: var(--code-margin) 0;
	padding: 20px;
	border: 1px solid rgba(255, 255, 255, .1);

	+ .code-toolbar,
	+ .highlight,
	+ .highlight .code-toolbar {
		border-top: 0;
		margin-top: calc(-1 * var(--code-margin));
	}

	pre, code {
		border: none;
	}

	code {
		display: block;
		color: inherit;
	}

  > .toolbar {
    button {
      font-size: .8em !important;
      background: hsla(0,0%,87.8%,.2) !important;
      color: #bbb !important;
      box-shadow: 0 2px 0 0 rgba(0,0,0,.2) !important;
      border-radius: 0 !important;
      margin: 6px !important;
      padding: 10px !important;
      user-select:none
    }
  }
}
